import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2014%2020%22%3E%3Cpath%20d%3D%22M7%200C3.14%200%200%203.141%200%207.002c0%204.791%206.264%2011.825%206.531%2012.122a.63.63%200%200%200%20.938%200C7.736%2018.827%2014%2011.794%2014%207.002%2014%203.14%2010.86%200%207%200zm0%2010.525a3.526%203.526%200%200%201-3.522-3.523A3.526%203.526%200%200%201%207%203.479a3.526%203.526%200%200%201%203.522%203.523A3.526%203.526%200%200%201%207%2010.525z%22%20%2F%3E%3C%2Fsvg%3E)
 */
export default function LocationIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 14 20'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M7 0C3.14 0 0 3.141 0 7.002c0 4.791 6.264 11.825 6.531 12.122a.63.63 0 0 0 .938 0C7.736 18.827 14 11.794 14 7.002 14 3.14 10.86 0 7 0zm0 10.525a3.526 3.526 0 0 1-3.522-3.523A3.526 3.526 0 0 1 7 3.479a3.526 3.526 0 0 1 3.522 3.523A3.526 3.526 0 0 1 7 10.525z'
        }
      />
    </svg>
  )
}
