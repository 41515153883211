import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%20600%20476.6%22%3E%3Cpath%20d%3D%22M0%20252.152h149.836v-18.578c0-27.699-15.324-53.062-39.992-66.187l-7.953-4.235v-44.375H47.94v44.375l-7.949%204.235C15.324%20180.516%200%20205.875%200%20233.574zm359.672-18.578v18.578h149.832v-18.578c0-27.699-15.324-53.062-39.992-66.187l-7.95-4.235v-44.375h-53.949v44.375l-7.953%204.235c-24.668%2013.129-39.988%2038.488-39.988%2066.187zM407.613%200h53.946v88.773h-53.946zM47.941%200h53.95v88.773H47.94zM0%20467h149.836v45H0zm359.672-184.848h149.832V437H359.672zm0%20184.848h149.832v45H359.672zM0%20282.152h149.836V437H0zm179.836-48.578v18.578h149.836v-18.578c0-27.699-15.324-53.062-39.992-66.187l-7.953-4.235v-44.375h-53.95v44.375l-7.949%204.235c-24.672%2013.129-39.992%2038.488-39.992%2066.187zM227.777%200h53.95v88.773h-53.95zm-47.941%20282.152h149.836V437H179.836zm0%20184.848h149.836v45H179.836zm0%200%22%2F%3E%3C%2Fsvg%3E)
 */
export default function BottlesIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 600 476.6'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M0 252.152h149.836v-18.578c0-27.699-15.324-53.062-39.992-66.187l-7.953-4.235v-44.375H47.94v44.375l-7.949 4.235C15.324 180.516 0 205.875 0 233.574zm359.672-18.578v18.578h149.832v-18.578c0-27.699-15.324-53.062-39.992-66.187l-7.95-4.235v-44.375h-53.949v44.375l-7.953 4.235c-24.668 13.129-39.988 38.488-39.988 66.187zM407.613 0h53.946v88.773h-53.946zM47.941 0h53.95v88.773H47.94zM0 467h149.836v45H0zm359.672-184.848h149.832V437H359.672zm0 184.848h149.832v45H359.672zM0 282.152h149.836V437H0zm179.836-48.578v18.578h149.836v-18.578c0-27.699-15.324-53.062-39.992-66.187l-7.953-4.235v-44.375h-53.95v44.375l-7.949 4.235c-24.672 13.129-39.992 38.488-39.992 66.187zM227.777 0h53.95v88.773h-53.95zm-47.941 282.152h149.836V437H179.836zm0 184.848h149.836v45H179.836zm0 0'
        }
      />
    </svg>
  )
}
