import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import useSWR from 'swr'

import { PermissionCategory } from 'core/remodel/refPaths'
import { AssetType, Currency } from 'core/remodel/types/common'
import { currencyOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchGlobalDashboard } from '@/api/CommonService'
import { getEmptyItems } from '@/utils/emptyState'
import { useAuthStore } from '@/store/authStore'
import { Autocomplete, Card, CardContent, CardHeader, CardSummary, CardTitle } from '@/components/base'
import { Carousel, CarouselItem } from '@/components/Carousel'
import { ChartDesc, DonutChart, StackedBarChart, TreemapChart } from '@/components/chart'
import { CollectableItem, CollectableItemProps } from '@/components/CollectableItem'
import DataExporter from '@/components/DataExporter'
import MapWidget, { PropertyCard } from '@/components/MapWidget'
import Summary from '@/components/Summary'

export default function HomePage() {
  const { t } = useTranslation()
  const { canView } = useAuthStore((state) => state.permissions)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const [displayedCurrency, setDisplayedCurrency] = useState<Currency>(preferences?.baseCurrency ?? Currency.USD)

  const handleDisplayedCurrency = (option: SingleValue<{ label: Currency; value: Currency }>) => {
    if (option !== null) {
      setDisplayedCurrency(option.value)
    }
  }

  // FIXME: Maybe there's a better way to meet MPT-7953 & MPT-16219
  useEffect(() => {
    setDisplayedCurrency(preferences.baseCurrency ?? Currency.USD)
  }, [preferences.baseCurrency])

  return (
    <motion.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={'mx-auto p-4 xl:max-w-screen-xl'}>
      <div className={'flex items-center justify-start gap-4'}>
        <h2 className={'mb-4 text-lg text-white'}>{t('GlobalDashboard')}</h2>
        <h2 id={'dashboard-header'} className={'mb-4 ml-auto text-grey'}>
          {t('CurrencyView')}
        </h2>
        <Autocomplete
          className={'mb-4 w-[200px] md:w-auto'}
          options={currencyOptions}
          value={currencyOptions.find(({ value }) => value === displayedCurrency)}
          onChange={handleDisplayedCurrency}
          placeholder={'Select Currency'}
          isMulti={false}
        />
        <DataExporter assetType={'Global'} className={'mb-4'} />
      </div>
      <div className={'space-y-5'}>
        <GlobalSummary currency={displayedCurrency} />
        <NetValueChart currency={displayedCurrency} />
        {canView('MyFinance') && <MyFinancesChart currency={displayedCurrency} />}
        {canView(PermissionCategory.Property) && <MyPropertiesChart currency={displayedCurrency} />}
        {canView('MyCollectables') && <MyCollectablesChart currency={displayedCurrency} />}
        {canView(PermissionCategory.Belonging) && <MyBelongingsChart currency={displayedCurrency} />}
      </div>
    </motion.main>
  )
}

function GlobalSummary({ currency }: { currency: Currency }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: global } = useSWR([commonQuery.global, currency], fetchGlobalDashboard(database!))
  const stats = useMemo(() => {
    const { assets, liabilities, netValue } = global ?? {}
    const unit = assets?.currency ?? preferences.baseCurrency ?? Currency.USD
    const value = {
      assets: assets?.value ?? NaN,
      liabilities: liabilities?.value ?? NaN,
      netValue: netValue?.value ?? NaN
    }
    return [
      { label: t('dashboard:MyAssets'), value: value.assets, unit },
      { label: t('dashboard:MyLiabilities'), value: value.liabilities * -1, unit },
      { label: t('dashboard:MyNetWorth'), value: value.netValue, unit }
    ]
  }, [t, preferences, global])

  return <Summary stats={stats} />
}

function NetValueChart({ currency }: { currency: Currency }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: global } = useSWR([commonQuery.global, currency], fetchGlobalDashboard(database!))
  const data = useMemo(() => {
    const { valueDistribution } = global ?? {}

    const valueDistributionI18nMap = (label: string) => {
      return t(`common:${label}`)
    }
    const valueDistributionI18n = {
      ...valueDistribution,
      assets: valueDistribution?.assets.map((asset) => ({
        ...asset,
        label: valueDistributionI18nMap(asset.label) ?? asset.label
      })),
      liabilities: valueDistribution?.liabilities.map((liability) => ({
        ...liability,
        label: valueDistributionI18nMap(liability.label) ?? liability.label
      }))
    }

    const chartItems = {
      assets: (valueDistributionI18n?.assets ?? []).map(({ label, value }) => ({ name: label, value: value.value })),
      liabilities: (valueDistributionI18n?.liabilities ?? []).map(({ label, value }) => ({
        name: label,
        value: value.value
      }))
    }
    return { ...chartItems }
  }, [global, t])
  const config = useMemo(() => {
    const unit: Currency = global?.assets.currency ?? preferences.baseCurrency!
    const assetsConfig = {
      unit: unit,
      max: Math.max(
        0,
        data.assets.reduce((prev, { value }) => prev + value, 0)
      ),
      hiddenUnassigned: true
    }
    const liabilitiesConfig = {
      unit: unit,
      max: Math.max(
        0,
        data.liabilities.reduce((prev, { value }) => prev + value, 0)
      ),
      hiddenUnassigned: true
    }
    return {
      assets: assetsConfig,
      liabilities: liabilitiesConfig
    }
  }, [data, preferences, global])

  return (
    <Card>
      <CardHeader className={'bg-grey/20 py-4'}>
        <CardTitle className={'text-sm font-medium uppercase'}>{t('NetValue')}</CardTitle>
      </CardHeader>
      <CardContent className={'space-y-2'}>
        <h4 className={'text-sm font-medium uppercase'}>{t('Assets')}</h4>
        <StackedBarChart data={data.assets} {...config.assets} />
        <h4 className={'text-sm font-medium uppercase'}>{t('Liabilities')}</h4>
        <StackedBarChart data={data.liabilities} {...config.liabilities} />
      </CardContent>
    </Card>
  )
}

function MyFinancesChart({ currency }: { currency: Currency }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: global } = useSWR([commonQuery.global, currency], fetchGlobalDashboard(database!))
  const data = useMemo(() => {
    const { assets, liabilities, netValue, distribution } = global?.myFinances ?? {}

    const financeI18nMap = (label: string) => {
      return t(`finances:${label}`)
    }
    const distributionI18n = {
      ...distribution,
      assets: distribution?.assets.map((asset) => ({
        ...asset,
        label: financeI18nMap(asset.label)
      })),
      liabilities: distribution?.liabilities.map((liability) => ({
        ...liability,
        label: financeI18nMap(liability.label)
      }))
    }

    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: (liabilities?.value ?? NaN) * -1,
        netValue: netValue?.value ?? NaN
      },
      assets: (distributionI18n?.assets ?? []).map(({ label, value }) => ({ name: label, value: value.value })),
      liabilities: (distributionI18n?.liabilities ?? []).map(({ label, value }) => ({
        name: label,
        value: value.value * -1
      }))
    }
  }, [global, t])
  const config = useMemo(() => {
    return {
      unit: global?.assets.currency ?? preferences.baseCurrency!
    }
  }, [preferences, global])

  return (
    <Card>
      <div>
        <CardSummary title={t('dashboard:MyFinances')} data={data.summary} {...config} />
      </div>
      <CardContent className={'grid gap-4 lg:grid-cols-2'}>
        <div className={'grid grid-cols-1 items-center gap-2 sm:grid-cols-2'}>
          <DonutChart label={t('Assets')} data={data.assets} {...config} />
          <ChartDesc data={data.assets} {...config} />
        </div>
        <div className={'grid grid-cols-1 items-center gap-2 sm:grid-cols-2'}>
          <DonutChart label={t('Liabilities')} data={data.liabilities} {...config} />
          <ChartDesc data={data.liabilities} {...config} />
        </div>
      </CardContent>
    </Card>
  )
}

function MyPropertiesChart({ currency }: { currency: Currency }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: global } = useSWR([commonQuery.global, currency], fetchGlobalDashboard(database!))
  const data = useMemo(() => {
    const { assets, liabilities, netValue, properties = [] } = global?.myProperties ?? {}
    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: liabilities?.value ?? NaN,
        netValue: netValue?.value ?? NaN
      },
      locations: properties.map(({ id, location }) => ({ item: { id, latLng: location.center } }))
    }
  }, [global])
  const config = useMemo(() => ({ unit: global?.assets.currency ?? preferences.baseCurrency! }), [preferences, global])

  return (
    <Card>
      <CardSummary title={t('dashboard:MyProperties')} data={data.summary} isAssociatedLiabilities={true} {...config} />
      <CardContent className={'relative overflow-hidden p-0'}>
        <MapWidget
          locations={data.locations}
          renderItem={PropertyCard}
          currency={currency}
          placeholder={{ title: t('EmptyPropertyTitle'), subText: t('EmptyPropertyMapSub') }}
        />
      </CardContent>
    </Card>
  )
}

// NEED TO ASK
function MyCollectablesChart({ currency }: { currency: Currency }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: global } = useSWR([commonQuery.global, currency], fetchGlobalDashboard(database!))
  const data = useMemo(() => {
    const { assets, liabilities, netValue, subtypeItems = [] } = global?.myCollectables ?? {}
    const reOrderedSubtypeItems = [
      ...subtypeItems.filter((item) => item.assetType === AssetType.Art),
      ...subtypeItems.filter((item) => item.assetType === AssetType.WineAndSpirits),
      ...getEmptyItems({
        assetType: AssetType.OtherCollectables,
        subtypeItems: subtypeItems.filter((item) => item.assetType === AssetType.OtherCollectables),
        length: 4,
        currency,
        mixedEmpty: true
      })
    ]
    const getValue = (targetType: AssetType) =>
      subtypeItems
        .filter(({ assetType }) => assetType === targetType)
        .reduce((prev, { value }) => prev + value.value, 0)
    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: liabilities?.value ?? NaN,
        netValue: netValue?.value ?? NaN
      },
      carouselItems: reOrderedSubtypeItems as CollectableItemProps[],
      chartItems: [
        { name: t(`AssetTypeOptions.Art`), value: getValue(AssetType.Art) },
        { name: t(`AssetTypeOptions.WineAndSpirits`), value: getValue(AssetType.WineAndSpirits) },
        { name: t(`AssetTypeOptions.OtherCollectables`), value: getValue(AssetType.OtherCollectables) }
      ]
    }
  }, [t, global, currency])
  const config = useMemo(() => {
    return {
      unit: global?.assets.currency ?? preferences.baseCurrency!
    }
  }, [preferences, global])

  return (
    <Card>
      <CardSummary
        title={t('dashboard:MyCollectables')}
        data={data.summary}
        isAssociatedLiabilities={true}
        {...config}
      />
      <CardContent>
        <div className={'grid grid-cols-1 items-center gap-4 lg:grid-cols-4'}>
          <Carousel containerClassName={'lg:col-span-3'}>
            {data.carouselItems.map((item, index) => (
              <CarouselItem key={index}>
                <CollectableItem {...item} />
              </CarouselItem>
            ))}
          </Carousel>
          <DonutChart data={data.chartItems} {...config} />
        </div>
      </CardContent>
    </Card>
  )
}

function MyBelongingsChart({ currency }: { currency: Currency }) {
  const { t } = useTranslation()
  const router = useRouter()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: global } = useSWR([commonQuery.global, currency], fetchGlobalDashboard(database!))
  const data = useMemo(() => {
    const { assets, liabilities, netValue, distribution } = global?.myBelongings ?? {}
    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: liabilities?.value ?? NaN,
        netValue: netValue?.value ?? NaN
      },
      chartItems: (distribution?.assets ?? []).map(({ label, value }) => ({ name: label, value: value.value }))
    }
  }, [global])
  const config = useMemo(() => {
    return {
      unit: global?.assets.currency ?? preferences.baseCurrency!
    }
  }, [preferences, global])

  const i18nParse = (value: string) => t(`collectables:OtherCollectableTypeOptions.${value}`, value)

  return (
    <Card>
      <CardSummary title={t('dashboard:MyBelongings')} data={data.summary} isAssociatedLiabilities={true} {...config} />
      <CardContent>
        <TreemapChart
          data={data.chartItems}
          onNavigate={(value) => router.push(`/belongings/summary/list/?subType=${value}`)}
          parse={i18nParse}
          {...config}
        />
      </CardContent>
    </Card>
  )
}
