import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%20%20%20%20viewBox%3D%220%200%2024%2024%22%20%20%20%20%20%20fill%3D%22none%22%20%20%20%20%20%20stroke%3D%22%23ddd%22%20%20%20%20%20%20stroke-width%3D%222%22%20%20%20%20%20%20stroke-linecap%3D%22round%22%20%20%20%20%20%20stroke-linejoin%3D%22round%22%20%20%20%20%20%20width%3D%2224%22%20%20%20%20%20%20height%3D%2224%22%20%20%20%20%3E%3Cpath%20d%3D%22M12%2017V3%22%20%2F%3E%3Cpath%20d%3D%22m6%2011%206%206%206-6%22%20%2F%3E%3Cpath%20d%3D%22M19%2021H5%22%20%2F%3E%3C%2Fsvg%3E)
 */
export default function ExportIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      stroke={'currentColor'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      width={size}
      height={size}
      {...props}
    >
      <path d={'M12 17V3'} />
      <path d={'m6 11 6 6 6-6'} />
      <path d={'M19 21H5'} />
    </svg>
  )
}
